
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { CompanyClient } from '@/services/Services';
import InfoModal from '@/components/modals/infoModal.vue';
import utils from '@/utils';
import * as OM from '@/Model';

@Options({})
export default class editCompanyModal extends Vue {

    @Prop() company: OM.Company;

    vm: OM.Company = null;

    tempDomain: string = "";

    created(){
        if(!this.company){
            this.vm = new OM.Company();
        } else {
            this.vm = utils.JsonClone(this.company);
        }
    }

    get canSave(){
        return !this.vm.companyName.trim();
    }

    addDomain(){
        if(!this.tempDomain.trim())
            return;

        var firstChar = this.tempDomain.substring(0, 1);
        if(firstChar != '@')
            this.tempDomain = "@" + this.tempDomain;

        var temp = this.tempDomain.trim().toLowerCase();
        
        if(this.vm.domains.indexOf(temp) != -1){
            this.tempDomain = "";
            return;
        }

        this.vm.domains.push(temp);
        this.tempDomain = "";
    }

    removeDomain(indice: number){
        this.vm.domains.splice(indice, 1);
    }

    save() {
        CompanyClient.save(this.vm)
        .then(x => {
            this.$emit('close');
        })
        .catch(y => {
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("Error", "Error"),
                subtitle: y.Message,
                deny: this.$localizationService.getLocalizedValue("Close", "Close"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })

    }
    
}
